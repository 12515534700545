const Color = [
    {
        id: 0,
        name: 'D',
        code: '1',
    },
    {
        id: 1,
        name: 'E',
        code: '2',
    },
    {
        id: 2,
        name: 'F',
        code: '3',
    },
    {
        id: 3,
        name: 'G',
        code: '4',
    },
    {
        id: 4,
        name: 'H',
        code: '5',
    },
    {
        id: 5,
        name: 'I',
        code: '6',
    },
    {
        id: 6,
        name: 'J',
        code: '7',
    },
    {
        id: 7,
        name: 'K',
        code: '8',
    },
    {
        id: 8,
        name: 'L',
        code: '9',
    },
    {
        id: 9,
        name: 'M',
        code: '10',
    },
    {
        id: 10,
        name: 'N',
        code: '11',
    },
    {
        id: 11,
        name: 'O-Z',
        code: '12',
    }
]

export default Color;
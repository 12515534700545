const Size = [
    {
        id: 0,
        name: '0.30-0.39',
        code: '7',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 1,
        name: '0.40-0.49',
        code: '8',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 2,
        name: '0.50-0.69',
        code: '10',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 3,
        name: '0.70-0.89',
        code: '11',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 4,
        name: '0.90-0.99',
        code: '12',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 5,
        name: '1.00-1.49',
        code: '13',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 6,
        name: '1.50-1.99',
        code: '14',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 7,
        name: '2.00-2.99',
        code: '15',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 8,
        name: '3.00-3.99',
        code: '16',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 9,
        name: '4.00-4.99',
        code: '17',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 10,
        name: '5.00-5.99',
        code: '18',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 11,
        name: '6.00-9.99',
        code: '19',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 12,
        name: '10.00-99.99',
        code: '20',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    }
]

export default Size
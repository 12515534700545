import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'
import * as XLSX from 'xlsx';

function Stock() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showLoader, setShowLoader] = useState(false)
    const [fileTrack, setFileTrack] = useState(true);
    const [formData, setFormData] = useState();
    const [P_CODE, setPCODE] = useState();

    const requiredColumns = [
        "StockID",
        "Certificate No",
        "Shape",
        "Size",
        "Color",
        "Clarity",
        "Cut",
        "Polish",
        "Sym",
        "Floro",
        "Lab",
        "RapPrice",
        "Dis",
        "cost per carat",
        "cost",
        "Measurements",
        "Table",
        "Depth",
        "Ratio",
        "Status",
        "Comment",
        "Video URL",
        "Image URL",
        "Cert URL",
        "Girdle",
        "Culet",
        "CAngle",
        "CHeight",
        "PAngle",
        "PDepth",
        "Fancy Intensity",
        "Fancy Overtone",
        "Fancy Color",
        "Location",
    ];

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

                if (sheetData.length > 0) {
                    const headerRow = sheetData[0];
                    const missingColumns = requiredColumns.filter(column => !headerRow.includes(column));

                    if (missingColumns.length === 0) {
                        const dataRows = sheetData.slice(1); // Exclude header row
                        const hyperlinkData = {};

                        // Extract hyperlink data using both Hyperlinks object and formulas
                        if (worksheet.Hyperlinks) {
                            worksheet.Hyperlinks.forEach(hyperlink => {
                                const [row, col] = XLSX.utils.decode_range(hyperlink.cell);
                                const columnName = headerRow[col];

                                hyperlinkData[`${columnName}_link`] = hyperlink.address;
                            });
                        }

                        // Extract hyperlink data from formulas
                        for (let rowIndex = 1; rowIndex < sheetData.length; rowIndex++) {
                            const row = sheetData[rowIndex];
                            for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                                const columnName = headerRow[columnIndex];
                                const cellValue = row[columnIndex];

                                const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex });
                                const cell = worksheet[cellAddress];

                                try {
                                    if (cell && cell.l && cell.l.Target) {
                                        const hyperlink = cell.l.Target;
                                        hyperlinkData[`${columnName}_link`] = hyperlink;
                                    } else if (cell && cell.l && cell.l.display) {
                                        // Extract hyperlink from display text
                                        const match = /http?:\/\/[^\s)]+/i.exec(cell.l.display);
                                        if (match && match[0]) {
                                            const hyperlink = match[0];
                                            hyperlinkData[`${columnName}_link`] = hyperlink;
                                        }
                                    } else if (cell && cell.f) {
                                        // Extract hyperlink from formula
                                        const formulaMatch = /"([^"]+\.com[^"]*)"/.exec(cell.f);
                                        if (formulaMatch && formulaMatch[1]) {
                                            const hyperlink = formulaMatch[1];
                                            hyperlinkData[`${columnName}_link`] = hyperlink;
                                        }
                                    }

                                } catch (error) {
                                    console.error('Error extracting hyperlink:', error);
                                }
                            }
                        }

                        // Set formData with the file and hyperlink data
                        const formData = new FormData();
                        formData.append('file', selectedFile);

                        // Append hyperlink data to the form data
                        Object.entries(hyperlinkData).forEach(([key, value]) => {
                            formData.append(key, value);
                        });

                        // Call the upload function with the file and hyperlink data
                        setFormData(formData);
                        setFileTrack(false);
                        // handleUploadFile(); // Avoid calling it here
                    } else {
                        setFileTrack(true);
                        toast.error(`Excel file is missing columns: ${missingColumns.join(', ')}`, {
                            position: "top-right",
                            autoClose: 3000,
                            // ... other toast options
                        });
                    }
                } else {
                    setFileTrack(true);
                    toast.error("Excel file is empty", {
                        position: "top-right",
                        autoClose: 3000,
                        // ... other toast options
                    });
                }
            };

            reader.readAsArrayBuffer(selectedFile);
        } else {
            console.error('Invalid file type. Please select a .xlsx file.');
        }
    };

    // Login function called.
    const handleSubmit = async () => {
        setShowLoader(true)
        if (email === '' || password === '') {
            toast.error('Please enter valid Username or Password!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setShowLoader(false)
            return;
        }
        if (fileTrack === true || formData === null) {
            toast.error('Please upload a valid excel file!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setShowLoader(false)
            return;
        }
        await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/login-stock`, {
            method: 'POST',
            body: JSON.stringify({
                USERNAME: email,
                PASSWORD: password
            }),
            headers: {
                'Content-type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setShowLoader(false)
                if (data.success === 'TRUE') {
                    setPCODE(data.message.P_CODE);
                    if (data.message.P_CODE) {
                        handleUploadFile(data.message.P_CODE)
                    } else {
                        toast.error('Part not found', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                } else {
                    // toast.error(data.message, {
                    toast.error(data.message.MSG, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
            .catch((err) => {
                setShowLoader(false)
            });

    }
    const [formattedDateTime, setFormattedDateTime] = useState('');

    useEffect(() => {
        const getCurrentDateTime = () => {
            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = String(currentDate.getFullYear());
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');
            const seconds = String(currentDate.getSeconds()).padStart(2, '0');

            const formattedDateTimeString = `${day}${month}${year}_${hours}${minutes}${seconds}`;
            setFormattedDateTime(formattedDateTimeString);
        };

        getCurrentDateTime();
    }, [fileTrack, formData]);

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    const handleUploadFile = async (P_CODE) => {
        if (formData && fileTrack === false) {
            try {
                formData.append('P_CODE', P_CODE);
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/upload`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    setShowLoader(false);
                    setEmail('');
                    setPassword('');
                    setFormData(null);
                    document.getElementById('file').value = '';

                    if (data.success === true) {

                        const trueData = data.message.filter(entry => entry.IS_MSG === 'TRUE');
                        const failedData = data.message.filter(entry => entry.IS_MSG === 'FALSE');

                        if (trueData.length !== 0) {
                            toast.success(`Data Uploaded successfully! Stones: ${trueData[0]?.Stone_Count}`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                        } else {
                            toast.warning(`${failedData[0]?.Stone_Count} Stones Uploaded............... Check Pending Stones Excel`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                        }

                        // Check if any entry in the message array has IS_MSG set to FALSE

                        if (failedData.length > 0) {
                            // Create Excel data
                            const excelData = failedData.map(entry => [
                                entry.StockID,
                                entry.ReportNo,
                                entry.Reason,
                            ]);

                            // Add header row
                            excelData.unshift(['StockID', 'ReportNo', 'Reason']);

                            // Create a new workbook
                            const ws = XLSX.utils.aoa_to_sheet(excelData);
                            const wb = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                            // Convert the workbook to a binary string
                            const wbBinary = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

                            // Create a Blob from the binary string
                            const blob = new Blob([s2ab(wbBinary)], { type: 'application/octet-stream' });

                            // Create a download link and trigger the download
                            const downloadLink = document.createElement('a');
                            downloadLink.href = URL.createObjectURL(blob);
                            downloadLink.download = `Pending_Upload_${formattedDateTime}.xlsx`;
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }
                    } else {
                        toast.error(data.message, {
                            // ... (your existing toast settings)
                        });
                    }
                } else {
                    // Handle non-successful response
                    setShowLoader(false);
                    console.error('Failed to upload file');
                }
            } catch (error) {
                // Handle fetch error
                setShowLoader(false);
                console.error('Error during fetch:', error);
            }
        }
    };
    return (
        <>
            <ToastContainer />
            <div className="login-container">
                <div className="login-form" style={{ background: "#ccc" }}>
                    <h1 style={{ textAlign: "center" }}>Login</h1>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={email}
                        className="input"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        className="input"

                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="file">File Upload:</label>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        accept=".xlsx"
                        className="input"
                        onChange={(e) => handleFileChange(e)}
                        required
                    />
                    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                        {
                            !showLoader && <button style={{ width: "30%" }} className="buttonSearch1" onClick={handleSubmit}>Submit</button>
                        }
                        {
                            showLoader && <button style={{ width: "30%" }} className="buttonSearch1" disabled>
                                <RotatingLines
                                    strokeColor="yellow"
                                    strokeWidth="10"
                                    animationDuration="0.75"
                                    width="17"
                                    visible={true}
                                />
                            </button>
                        }
                        <button className="button1" style={{ width: "70%", backgroundColor: "#240df4" }}>
                            <a
                                style={{ textDecoration: "none", color: "white", }}
                                href="/SampleData.xlsx" // path to your sample Excel file
                                download="format.xlsx" // default download filename
                            >
                                Download Sample Format
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stock;

// Table Coulmn

// [StockID] [varchar](512) ,
//     [Certificate No] [varchar](128) ,
//     [Shape] [varchar](1028) ,
//     [Size] [numeric](18, 2) ,
//     [Color] [varchar](1024) ,
//     [Clarity] [varchar](512) ,
//     [Cut] [varchar](512) ,
//     [Polish] [varchar](512) ,
//     [Symmetry] [varchar](512) ,
//     [FluorescenceIntensity] [varchar](512) ,
//     [Lab] [varchar](16) ,
//     [RapPrice] [numeric](18, 2) ,
//     [Dis] [numeric](8, 2) ,
//     [Rate] [numeric](18, 2) ,
//     [Amount] [numeric](18, 2) ,
//     [ADD_PER] [numeric](8, 2) ,
//     [FINAL_PER] [numeric](8, 2) ,
//     [FINAL_RATE] [numeric](18, 2) ,
//     [FINAL_AMOUNT] [numeric](18, 2) ,
//     [Measurement] [varchar](256) ,
//     [Table] [numeric](18, 2) ,
//     [Depth] [numeric](18, 2) ,
//     [Ratio] [numeric](18, 2) ,
//     [Status] [varchar](1024) ,
//     [Comment] [varchar](2056) ,
//     [Video URL] [varchar](2056) ,
//     [Image URL] [varchar](2056) ,
//     [Cert URL] [varchar](2056) ,
//     [Girdle] [varchar](1024) ,
//     [Culet] [varchar](1024) ,
//     [CAngle] [numeric](18, 2) ,
//     [CHeight] [numeric](18, 2) ,
//     [PAngle] [numeric](18, 2) ,
//     [PDepth] [numeric](18, 2) ,
//     [Fancy Intensity] [varchar](2056) ,
//     [Fancy Overtone] [varchar](2056) ,
//     [Fancy Color] [varchar](2056) ,
//     [Location] [varchar](2056) ,
//     [Length] [varchar](512) ,
//     [Width] [varchar](512) ,
//     [Height] [varchar](512) ,
//     [FancyFullName] [varchar](2048) ,
//     [GirdleThin] [varchar](2048) ,
//     [GirdleThick] [varchar](2048)
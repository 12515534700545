const Labdata = [
    {
        id: 0,
        name: 'GIA',
        code: 'GIA',
    },
    {
        id: 1,
        name: 'IGI',
        code: 'IGI',
    },
    {
        id: 2,
        name: 'GCAL',
        code: 'GCAL',
    }
]

export default Labdata;
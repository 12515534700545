const Floro = [
    {
        id: 0,
        name: 'None',
        code: '1',
    },
    {
        id: 1,
        name: 'Faint',
        code: '2',
    },
    {
        id: 2,
        name: 'Medium',
        code: '3',
    },
    {
        id: 3,
        name: 'Strong',
        code: '4',
    },
    {
        id: 4,
        name: 'Very Strong',
        code: '5',
    },
    {
        id: 5,
        name: 'Slight',
        code: '6',
    },
    {
        id: 6,
        name: 'Very Slight',
        code: '7',
    }
]

export default Floro

    ;
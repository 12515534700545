const Shape = [
    {
        id: 0,
        name: 'Round',
        code: 'R',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898486/Round_b9uwge.png',
    },
    {
        id: 1,
        name: 'Pear',
        code: 'PE',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898613/Pear_amsjgy.png',
    },
    {
        id: 2,
        name: 'Oval',
        code: 'O',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898612/Oval_voashr.png',
    },
    {
        id: 3,
        name: 'Marqulse',
        code: 'M',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898612/Marquise_hk4krm.png',
    },
    {
        id: 4,
        name: 'Heart',
        code: 'H',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898612/Heart_yramea.png',
    },
    {
        id: 5,
        name: 'Radiant',
        code: 'RA',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898613/Radiant_flhxsd.png',
    },
    {
        id: 6,
        name: 'Princess',
        code: 'PR',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898613/Princess_pxdam1.png',
    },
    {
        id: 7,
        name: 'Emerald',
        code: 'EM',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898612/Emerald_w5uvd7.png',
    },
    {
        id: 8,
        name: 'Cushlon',
        code: 'CU',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898611/Cushion_mwasqk.png',
    },
    {
        id: 9,
        name: 'Asscher',
        code: 'AS',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898402/Asscher_fet0um.png',
    },
    
    {
        id: 10,
        name: 'Trilliant',
        code: 'TR',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898613/Trilliant_vvhxad.png',
    },
    {
        id: 11,
        name: 'Triangle',
        code: 'TRN',
        image: 'https://res.cloudinary.com/delkg18k3/image/upload/v1709898613/Triangle_iwo33b.png',
    },
    {
        id: 12,
        name: 'Other',
        code: 'OTH',
        image: 'https://res.cloudinary.com/dhpvia1ae/image/upload/v1718281209/Other_tnlnph.png',
    },
]

export default Shape;
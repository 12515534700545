import React from 'react';
import './App.css';
import Login from './Pages/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Note: Removed Switch
import Store from './Pages/Store';
import Stock from './Pages/Stock';
import Search from './Pages/Search';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/stock" element={<Stock />} />
        <Route path="/inventory" element={<Store />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import Shape from '../Data/Shape';
import Size from '../Data/Size';
import Color from '../Data/Color';
import Clarity from '../Data/Clarity';
import Cut from '../Data/Cut';
import CloseImg from '../assets/download.png'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Floro from '../Data/Floro';
import Labdata from '../Data/Labdata';
import { Modal } from 'antd';


function Search() {

  const [isVendor] = useState(() => {
    const storedShape = localStorage.getItem("IS_VENDOR");
    return storedShape || '';
  });
  const [ShapeName, setShapeName] = useState(() => { const storedShape = localStorage.getItem("Shape"); return storedShape ? JSON.parse(storedShape) : []; });
  const [sizeCode, setSizecode] = useState(() => { const storedShape = localStorage.getItem("size"); return storedShape ? JSON.parse(storedShape) : []; });
  const [sizeFrom, setSizefrom] = useState(() => { const storedShape = localStorage.getItem("sizeFrom"); return storedShape ? JSON.parse(storedShape) : ''; });
  const [sizeTo, setSizeto] = useState(() => { const storedShape = localStorage.getItem("sizeTo"); return storedShape ? JSON.parse(storedShape) : []; });
  const [colorCode, setColorcode] = useState(() => { const storedShape = localStorage.getItem("Color"); return storedShape ? JSON.parse(storedShape) : []; });
  const [FancyOvertone, setFancyOvertone] = useState(() => {
    const storedFancyOvertone = localStorage.getItem("FancyOvertone");
    return storedFancyOvertone || '';
  });
  const [FancyIntensity, setFancyIntensity] = useState(() => {
    const storedShape = localStorage.getItem("FancyIntensity");
    return storedShape || '';
  });

  const [FancyColor, setFancyColor] = useState(() => {
    const storedShape = localStorage.getItem("FancyColor");
    return storedShape || '';
  });

  const [reportNo, setReport] = useState(() => {
    const storedShape = localStorage.getItem("reportNo");
    return storedShape || 'CERT';
  });

  const [location, setLocation] = useState(() => {
    const storedShape = localStorage.getItem("Location");
    return storedShape || '';
  });
  const [clarityCode, setClaritycode] = useState(() => { const storedShape = localStorage.getItem("clarityCode"); return storedShape ? JSON.parse(storedShape) : []; });
  const [FluoreCode, setFlorocode] = useState(() => { const storedShape = localStorage.getItem("FluoreCode"); return storedShape ? JSON.parse(storedShape) : []; });
  const [labCode, setLabcode] = useState(() => { const storedShape = localStorage.getItem("labCode"); return storedShape ? JSON.parse(storedShape) : []; });
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [stoneIdList, setStoneIdList] = useState(() => { const storedShape = localStorage.getItem("ReportNo:"); return storedShape ? JSON.parse(storedShape) : '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(() => {
    const storedShape = localStorage.getItem("Check");
    return storedShape === "true"; // Convert string to boolean
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLabGrown, setIsLabGrown] = useState(() => {
    const storedShape = localStorage.getItem("IS_LABGROWN") ? localStorage.getItem("IS_LABGROWN") : "yes";
    return storedShape === "yes"; // Convert string to boolean
  });

  const handleRadioButtonChange = (event) => {
    setIsLabGrown(event.target.value === 'LabGrown');
  };

  // WIthout Login function

  // useEffect(() => {
  //   const email = localStorage.getItem('USERNAME')
  //   const password = localStorage.getItem('PASSWORD')

  //   if (!email || !password) {
  //     navigate('/')
  //   }
  // }, [])

  //////////////////////////////////////////////////////////// Shape ///////////////////////////////////////////////////////////////////
  const handleBoxClick = (name) => {
    const isSelected = ShapeName.includes(name);
    if (isSelected) {
      setShapeName(ShapeName?.filter((code) => code !== name));
    } else {
      setShapeName([...ShapeName, name]);
    }
  };

  const handleClearSelection = () => {
    setShapeName([])
  };

  const handleSelectAllClick = () => {
    if (!selectAllChecked) {
      const shapeCodes = Shape.map(shape => shape.code);
      setShapeName(shapeCodes);
    } else {
      setShapeName([])
    }
    setSelectAllChecked(!selectAllChecked);
  };


  //////////////////////////////////////////////////////////// Size ///////////////////////////////////////////////////////////////////
  const handleBoxClick2 = (code) => {
    const isSelected = sizeCode.includes(code);
    if (isSelected) {
      setSizecode(sizeCode?.filter((size) => size !== code))
    } else {
      setSizecode([...sizeCode, code])
    }
  };
  const handleClearSelection2 = () => {
    setSizecode([])
    setSizefrom('')
    setSizeto('')
  };

  const Fromdata = (e) => {
    const value = e.target.value;

    // Regex to allow only digits and up to two decimal places
    const regex = /^\d{0,3}(?:\.\d{0,2})?$/;

    // Check if the input matches the regex pattern
    if (regex.test(value)) {
      setSizefrom(value);
    }
    // setSizefrom(sanitizedValue);
  };
  const Todata = (e) => {
    const value = e.target.value;

    // Regex to allow only digits and up to two decimal places
    const regex = /^\d{0,3}(?:\.\d{0,2})?$/;

    // Check if the input matches the regex pattern
    if (regex.test(value)) {
      setSizeto(value);
    }
  };

  //////////////////////////////////////////////////////////// Color ///////////////////////////////////////////////////////////////////
  const handleBoxClick3 = (code) => {
    const isSelected = colorCode.includes(code);
    if (isSelected) {
      setColorcode(colorCode?.filter((size) => size !== code))
    } else {
      setColorcode([...colorCode, code])
    }
  };
  const handleClearSelection3 = () => {
    setColorcode([])
  };

  const handelCheck = () => {
    setChecked(!checked)
    if (checked === false) {
      setColorcode([])
    }
    setFancyOvertone("")
    setFancyIntensity("")
    setFancyColor("")
  }

  //////////////////////////////////////////////////////////// Clarity ///////////////////////////////////////////////////////////////////
  const handleBoxClick4 = (code) => {
    const isSelected = clarityCode.includes(code);
    if (isSelected) {
      setClaritycode(clarityCode?.filter((size) => size !== code))
    } else {
      setClaritycode([...clarityCode, code])
    }
  };
  const handleClearSelection4 = () => {
    setClaritycode([])
  };

  //////////////////////////////////////////////////////////// Fluore ///////////////////////////////////////////////////////////////////
  const handleBoxClick6 = (code) => {
    const isSelected = FluoreCode.includes(code);
    if (isSelected) {
      setFlorocode(FluoreCode.filter((size) => size !== code));
    } else {
      setFlorocode([...FluoreCode, code])
    }
  };
  const handleClearSelection6 = () => {
    setFlorocode([])
  };

  //////////////////////////////////////////////////////////// Lab ///////////////////////////////////////////////////////////////////
  const handleBoxClick7 = (code) => {
    const isSelected = labCode.includes(code);
    if (isSelected) {
      setLabcode(labCode?.filter((size) => size !== code))
    } else {
      setLabcode([...labCode, code])
    }
  };
  const handleClearSelection7 = () => {
    setLabcode([])
  };



  //////////////////////// Cut ///////////////////////////
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(() => { const storedShape = localStorage.getItem("CutArray"); return storedShape ? JSON.parse(storedShape) : []; });
  const [options, setOptions] = useState(() => {
    const storedShape = localStorage.getItem("CutArray");
    const selectedOptions = storedShape ? JSON.parse(storedShape) : [];
    const unselectedOptions = Cut.filter(option => !selectedOptions.some(selectedOption => selectedOption.value === option.value))
    return unselectedOptions;
  });
  const cut = selectedOptions.map((option) => option.code)


  const handleIntensityChange2 = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue !== '') {
      const selectedOption = options.find(option => option.value === selectedValue);
      setSelectedOptions([...selectedOptions, selectedOption]);
      setOptions(options.filter(option => option.value !== selectedValue));
      setSelectedOption('');
    }
  };

  const handleRemoveOption = (option) => {
    // Remove the selected option from selectedOptions
    setSelectedOptions(prevSelectedOptions => prevSelectedOptions.filter(item => item.value !== option.value));
    setOptions([...options, option]);
  };


  const handleClearSelectioncut = () => {
    setSelectedOptions([]);
    setOptions(Cut);
  };

  //////////////////////// Polish ///////////////////////////
  const [selectedOption2, setSelectedOption2] = useState('');
  const [selectedOptions2, setSelectedOptions2] = useState(() => { const storedShape = localStorage.getItem("PolishArray"); return storedShape ? JSON.parse(storedShape) : []; });
  const [options2, setOptions2] = useState(() => {
    const storedShape = localStorage.getItem("PolishArray");
    const selectedOptions = storedShape ? JSON.parse(storedShape) : [];
    const unselectedOptions = Cut.filter(option => !selectedOptions.some(selectedOption => selectedOption.value === option.value))
    return unselectedOptions;
  });
  const Polish = selectedOptions2.map((option) => option.code)

  const handleIntensityChange22 = (event) => {
    const selectedValue2 = event.target.value;
    if (selectedValue2 !== '') {
      const selectedOption2 = options2.find(option => option.value === selectedValue2);
      setSelectedOptions2([...selectedOptions2, selectedOption2]);
      setOptions2(options2.filter(option => option.value !== selectedValue2));
      setSelectedOption2('');
    }
  };

  const handleRemoveOption2 = (option) => {
    setSelectedOptions2(selectedOptions2.filter(item => item.value !== option.value));
    setOptions2([...options2, option]);
  };

  const handleClearSelectionPolish = () => {
    setSelectedOptions2([]);
    setOptions2(Cut);
  };

  //////////////////////// Symmetry ///////////////////////////
  const [selectedOption3, setSelectedOption3] = useState('');
  const [selectedOptions3, setSelectedOptions3] = useState(() => { const storedShape = localStorage.getItem("SymmentryArray"); return storedShape ? JSON.parse(storedShape) : []; });
  const [options3, setOptions3] = useState(() => {
    const storedShape = localStorage.getItem("SymmentryArray");
    const selectedOptions = storedShape ? JSON.parse(storedShape) : [];
    const unselectedOptions = Cut.filter(option => !selectedOptions.some(selectedOption => selectedOption.value === option.value))
    return unselectedOptions;
  });
  const Symmetry = selectedOptions3.map((option) => option.code)

  const handleIntensityChange23 = (event) => {
    const selectedValue3 = event.target.value;
    if (selectedValue3 !== '') {
      const selectedOption3 = options3.find(option => option.value === selectedValue3);
      setSelectedOptions3([...selectedOptions3, selectedOption3]);
      setOptions3(options3.filter(option => option.value !== selectedValue3));
      setSelectedOption3('');
    }
  };

  const handleRemoveOption3 = (option) => {
    setSelectedOptions3(selectedOptions3.filter(item => item.value !== option.value));
    setOptions3([...options3, option]);
  };

  const handleClearSelectionSymmetry = () => {
    setSelectedOptions3([]);
    setOptions3(Cut);
  };

  const handleQuillChange = (value) => {
    const sanitizedValue = value.replace(/\s+/g, ',');
    setStoneIdList(sanitizedValue);
  };


  const handleSearch = async () => {
    if (ShapeName.length > -1) {
      let string = JSON.stringify(ShapeName)
      localStorage.setItem("Shape", string)
    }
    if (sizeCode.length > -1) {
      let string = JSON.stringify(sizeCode)
      localStorage.setItem("size", string)
    }

    if (sizeFrom.length > -1) {
      let size = JSON.stringify(sizeFrom)
      localStorage.setItem("sizeFrom", size)
    }
    if (sizeTo.length > -1) {
      let size = JSON.stringify(sizeTo)
      localStorage.setItem("sizeTo", size)
    }

    if (colorCode.length > -1) {
      let string = JSON.stringify(colorCode)
      localStorage.setItem("Color", string)
    }

    localStorage.setItem("FancyOvertone", FancyOvertone)
    localStorage.setItem("FancyIntensity", FancyIntensity)
    localStorage.setItem("FancyColor", FancyColor)
    localStorage.setItem("reportNo", reportNo)
    localStorage.setItem("Check", checked)
    localStorage.setItem("Location", location)
    localStorage.setItem("IS_LABGROWN", isLabGrown === true ? 'yes' : 'no')
    if (clarityCode.length > -1) {
      let string = JSON.stringify(clarityCode)
      localStorage.setItem("clarityCode", string)
    }
    if (cut.length > -1) {
      let string = JSON.stringify(cut)
      let string1 = JSON.stringify(selectedOptions)
      localStorage.setItem("CutArray", string1)
      localStorage.setItem("Cut", string)
    }
    if (Polish.length > -1) {
      let string = JSON.stringify(Polish)
      let string1 = JSON.stringify(selectedOptions2)
      localStorage.setItem("PolishArray", string1)
      localStorage.setItem("Polish", string)
    }
    if (Symmetry.length > -1) {
      let string = JSON.stringify(Symmetry)
      let string1 = JSON.stringify(selectedOptions3)
      localStorage.setItem("SymmentryArray", string1)
      localStorage.setItem("Symmetry", string)
    }
    if (FluoreCode.length > -1) {
      let string = JSON.stringify(FluoreCode)
      localStorage.setItem("FluoreCode", string)
    }
    if (labCode.length > -1) {
      let string = JSON.stringify(labCode)
      localStorage.setItem("labCode", string)
    }
    if (stoneIdList.length > -1) {
      let string = JSON.stringify(stoneIdList)
      localStorage.setItem("ReportNo:", string)
    }
    navigate('/inventory');
  };

  const handleReset = async () => {
    window.location.reload();
    localStorage.removeItem("Shape");
    localStorage.removeItem("sizeFrom");
    localStorage.removeItem("sizeTo");
    localStorage.removeItem("size");
    localStorage.removeItem("Color");
    localStorage.removeItem("FancyOvertone");
    localStorage.removeItem("FancyIntensity");
    localStorage.removeItem("FancyColor");
    localStorage.removeItem("reportNo");
    localStorage.removeItem("Check");
    localStorage.removeItem("clarityCode");
    localStorage.removeItem("Cut");
    localStorage.removeItem("Polish");
    localStorage.removeItem("Symmetry");
    localStorage.removeItem("FluoreCode");
    localStorage.removeItem("labCode");
    localStorage.removeItem("ReportNo:");
    localStorage.removeItem("SymmentryArray");
    localStorage.removeItem("PolishArray");
    localStorage.removeItem("CutArray");
    localStorage.removeItem("Location");
    localStorage.removeItem("IS_LABGROWN");
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const handleLogOut = () => {
    localStorage.removeItem('USERNAME')
    localStorage.removeItem('PASSWORD')
    localStorage.removeItem('IS_HOLD')
    localStorage.removeItem('IS_CONFIRM')
    localStorage.removeItem('IS_CLIENT_PRICE')
    localStorage.removeItem('IS_VENDOR')
    navigate('/')
  };


  return (
    <div>
      <ToastContainer />
      <div className='maindiv1 bg-gray-200 rounded-lg m-5 p-4 flex flex-col md:flex-row items-center md:items-start'>
        <div className='flex md:flex-col justify-start w-full md:w-[90px]'>
          <div className='flex heading text-lg font-medium w-full md:w-[80px] justify-start items-center md:justify-normal'>
            Shape
            {ShapeName.length > 0 && (
              <span className='pl-1' onClick={handleClearSelection}>
                <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
              </span>
            )}
          </div>
          <div className='flex linkbtn absolute md:static left-28 top-2.5 cursor-pointer underline !text-blue-500 !w-28 justify-center md:justify-normal ' onClick={handleSelectAllClick}>
            <span className='w-max'>{selectAllChecked ? 'Deselect All' : "Select All"}</span>
          </div>
        </div>
        <div className='flex flex-wrap gap-2 w-full md:w-auto justify-center md:justify-normal'>
          {Shape?.map((shap, index) => (
            <div key={index} className={`box rounded-md border-2 border-gray-400 ${ShapeName.includes(shap.code) ? 'selected' : ''}`} onClick={() => handleBoxClick(shap.code)}>
              <div className='w-full flex justify-center items-center'>
                <img src={shap.image} className='self-center w-12 h-12 md:w-16 md:h-16' />
              </div>
              <div className='shapName text-sm md:text-md text-center'>{shap.name}</div>
            </div>
          ))}
        </div>
        <button onClick={openModal} class="logout1-hover absolute top-4 right-6 px-3 py-1" >
          Log Out
        </button>
      </div>

      <div className='maindiv bg-gray-200 rounded-lg flex flex-col md:flex-row' onTouchStart={(e) => e.preventDefault()}>
        <div className=' flex items-center self-start w-full md:w-auto'>
          <div className='heading text-lg font-medium w-[90px] flex'>
            Size
            {sizeCode.length > 0 && (
              <span className='pl-1' onClick={handleClearSelection2}>
                <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
              </span>
            )}
          </div>
          <div className='FromTo flex flex-row items-center ml-4 md:ml-2'>
            <input
              type='number'
              value={sizeFrom}
              placeholder='From...'
              className='h-8 w-16 px-2 outline-none text-center'
              autoFocus={false}
              onChange={Fromdata}
              maxLength={3}
            />
            <span className='mx-2'>-</span>
            <input
              type='number'
              value={sizeTo}
              placeholder='To...'
              autoFocus={false}
              className='h-8 w-16 px-2 outline-none text-center'
              onChange={Todata}
            />
          </div>
        </div>

        <div className='downPart flex flex-col md:flex-row items-center justify-center md:justify-start gap-4 w-full'>
          <div className=' grid grid-cols-4 md:flex md:flex-wrap gap-2 md:gap-3 w-full md:w-auto justify-center md:pl-20'>
            {Size?.map((size, index) => (
              <div key={index} className={`box2 rounded-md border border-gray-400 text-center ${sizeCode.includes(size.code) ? 'selected' : ''}`} onClick={() => handleBoxClick2(size.code)}>
                <div className='shapName text-xs md:text-sm text-center' >{size.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='maindiv bg-gray-200 rounded-lg m-5 p-4 flex flex-col md:flex-row'>
        <div className='heading text-lg font-medium w-full md:w-[85px] flex'>
          Color
          {colorCode.length > 0 && (
            <span className='pl-1' onClick={handleClearSelection3}>
              <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
            </span>
          )}
        </div>

        <div className='spacebetween flex flex-col md:flex-row gap-4 w-full'>
          <div className='w-auto md:w-max flex flex-wrap gap-1 '>
            {Color?.map((color, index) => (
              <div className='FromTo' key={index}>
                <div className={`colortext1 ${colorCode.includes(color.code) ? 'selected' : ''}`} style={{ backgroundColor: checked ? "#eaf3f6" : "white" }} onClick={() => {
                  if (checked) {
                  } else {
                    handleBoxClick3(color.code)
                  }
                }}>
                  {color.name}
                </div>
              </div>
            ))}
          </div>

          <div className='grid grid-rows-4 md:grid-rows-2 2xl:grid-rows-2 grid-flow-col gap-1 w-full md:w-1/2'>
            <div className='flex items-center md:items-start w-full md:w-auto'>
              <div className='colorname w-max' >
                <span><input type='checkbox' checked={checked} onChange={handelCheck} /> Fancy Color</span>
              </div>
            </div>

            <div className='flex items-center mb-2 md:mb-0'>
              <div className='colorname w-20 md:w-auto'>Intensity &nbsp;</div>
              <select onChange={(e) => setFancyIntensity(e.target.value)} value={FancyIntensity} disabled={!checked} className={`w-40 border border-black ${!checked ? 'bg-[#eaf3f6]' : ''}`}>
                {!checked ? <option value='' className='bg-gray-200' selected >Select</option> : null}
                <option value='' className='bg-gray-200' selected >Select</option>
                <option value='BLUE'>BLUE</option>
                <option value='DEEP'>DEEP</option>
                <option value='FAINT'>FAINT</option>
                <option value='GREEN'>GREEN</option>
                <option value='INTENSE'>INTENSE</option>
                <option value='LIGHT'>LIGHT</option>
                <option value='NO'>NO</option>
                <option value='ORANGE'>ORANGE</option>
                <option value='PINK'>PINK</option>
                <option value='VERYLIGHT'>VERYLIGHT</option>
                <option value='VIVID'>VIVID</option>
                <option value='YELLOW'>YELLOW</option>
              </select>
            </div>

            <div className='flex items-center'>
              <div className='colorname w-20 md:w-auto'>Overtone &nbsp;</div>
              <select onChange={(e) => setFancyOvertone(e.target.value)} disabled={!checked} value={FancyOvertone} className={`w-40 border border-black ${!checked ? 'bg-[#eaf3f6]' : ''}`}>
                {!checked ? <option value='' className='bg-gray-200' selected >Select</option> : null}
                <option value='' className='bg-gray-200' selected >Select</option>
                <option value='BLUE'>BLUE</option>
                <option value='BLUISH'>BLUISH</option>
                <option value='BROWN'>BROWN</option>
                <option value='BROWNISH'>BROWNISH</option>
                <option value='BROWNISH GREENISH'>BROWNISH GREENISH</option>
                <option value='DEEP'>DEEP</option>
                <option value='GREEN'>GREEN</option>
                <option value='GREENISH'>GREENISH</option>
                <option value='GREYISH'>GREYISH</option>
                <option value='GREYISH GREENISH'>GREYISH GREENISH</option>
                <option value='INTENSE'>INTENSE</option>
                <option value='NO'>NO</option>
                <option value='ORANGE'>ORANGE</option>
                <option value='ORANGY'>ORANGY</option>
                <option value='PINK'>PINK</option>
                <option value='PINKISH'>PINKISH</option>
                <option value='PURPLE'>PURPLE</option>
                <option value='PURPLISH'>PURPLISH</option>
                <option value='RED'>RED</option>
                <option value='REDDISH'>REDDISH</option>
                <option value='VIVID'>VIVID</option>
                <option value='YELLOW'>YELLOW</option>
                <option value='YELLOWISH'>YELLOWISH</option>
              </select>
            </div>

            <div className='flex items-center'>
              <div className='colorname w-20 md:w-auto'>Color &nbsp;</div>
              <select style={{ marginLeft: window.innerWidth >= 550 ? '29px' : '0px' }} onChange={(e) => setFancyColor(e.target.value)} value={FancyColor} disabled={!checked} className={`w-40 border border-black ${!checked ? 'bg-[#eaf3f6]' : ''}`}>
                {!checked ? <option value='' className='bg-gray-200' selected >Select</option> : null}
                <option value='' className='bg-gray-200' selected >Select</option>
                <option value='BLUE'>BLUE</option>
                <option value='BLUISH'>BLUISH</option>
                <option value='BROWN'>BROWN</option>
                <option value='BROWNISH'>BROWNISH</option>
                <option value='GREEN'>GREEN</option>
                <option value='NO'>NO</option>
                <option value='ORANGE'>ORANGE</option>
                <option value='PINK'>PINK</option>
                <option value='PINK PURPLE'>PINK PURPLE</option>
                <option value='RED'>RED</option>
                <option value='YELLOW'>YELLOW</option>
              </select>
            </div>
          </div>

        </div>
      </div>

      <div className='maindiv bg-gray-200 rounded-lg m-5 p-4 flex flex-col md:flex-row'>
        <div className='heading font-medium md:w-[85px] flex self-start '>
          Clarity
          {clarityCode.length > 0 && (
            <span className='pl-1' onClick={handleClearSelection4}>
              <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
            </span>
          )}
        </div>
        <div className='spacebetween flex flex-col md:flex-row gap-4 w-full'>
          <div className='md:w-max flex flex-wrap !gap-1'>
            {Clarity?.map((clarity, index) => (
              <div className='FromTo text-[15px]' key={index}>
                <div className={`colortext ${clarityCode.includes(clarity.code) ? 'selected' : ''}`} onClick={() => handleBoxClick4(clarity.code)}>
                  {clarity.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='maindiv bg-gray-200 rounded-lg m-5 p-4 flex flex-col md:flex-row'>
        <div className=' flex md:flex md:flex-row w-full md:w-1/3 items-center '>
          <div className='md:flex  md:flex-row  md:justify-start md:items-center gap-20 w-full md:w-1/2 md:h-[40px]'>

            <div className='flex items-center mb-2 md:mb-0'>
              <div className='w-24 text-lg font-medium colorname flex' >
                Cut &nbsp;
                {selectedOptions.length > 0 && (
                  <span className='pl-1' onClick={handleClearSelectioncut}>
                    <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
                  </span>
                )}
              </div>
              <div className="dropdown">
                <div className="selected-options bg-white border border-black grid grid-cols-3 gap-3 md:flex p-1 mx-w-20 md:w-auto">
                  {selectedOptions.map((option, index) => (
                    <div key={index} className='border border-black bg-gray-100 rounded-xl p-1 flex items-center gap-2 md:w-12'>
                      {option.label}
                      <img src={CloseImg} className='cursor-pointer h-4 w-4' onClick={() => handleRemoveOption(option)} />
                    </div>
                  ))}

                  {options.length > 0 ? (
                    <select onChange={handleIntensityChange2} value={selectedOption} className='select-style'>
                      <option value='' className='bg-gray-200' disabled>Select</option>
                      {options
                        .filter(option => !selectedOptions.some(selectedOption => selectedOption.value === option.value)) // Filter out selected options
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                  ) : null}

                </div>
              </div>
            </div>

            <div className='flex md:items-center md:justify-center mb-2 md:mb-0'>
              <div className='w-24 md:w-[67px] text-lg font-medium colorname flex'>
                Polish
                {selectedOptions2.length > 0 && (
                  <span onClick={handleClearSelectionPolish}>
                    <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full ml-1' alt='Close' />
                  </span>
                )}
              </div>
              <div className="dropdown">
                <div className="selected-options bg-white border border-black grid grid-cols-3 gap-3 md:flex mx-w-20 md:w-auto p-1" >
                  {selectedOptions2.map((option, index) => (
                    <div key={index} className='selected-option border border-black bg-gray-100 rounded-xl p-1 flex items-center gap-2 md:w-12'>
                      {option.label}
                      <img src={CloseImg} className='cursor-pointer h-4 w-4' onClick={() => handleRemoveOption2(option)} />
                    </div>
                  ))}
                  {options2.length > 0 ? (
                    <select onChange={handleIntensityChange22} value={selectedOption2} className='select-style'>
                      <option value='' className='bg-gray-200' disabled >Select</option>
                      {options2
                        .filter(option => !selectedOptions2.some(selectedOption => selectedOption.value === option.value)) // Filter out selected options
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className='flex items-center mb-2 md:mb-0'>
              <div className='w-24 md:w-[105px] text-lg font-medium colorname flex'>
                Symmetry
                {selectedOptions3.length > 0 && (
                  <span className='pl-1' onClick={handleClearSelectionSymmetry}>
                    <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
                  </span>
                )}
              </div>
              <div className="dropdown">
                <div className="selected-options bg-white border border-black grid grid-cols-3 gap-3 md:flex mx-w-20 md:w-auto p-1" >
                  {selectedOptions3.map((option, index) => (
                    <div key={index} className='selected-option border border-black bg-gray-100 rounded-xl p-1 flex items-center gap-2 md:w-12' >
                      {option.label}
                      <img src={CloseImg} className='cursor-pointer h-4 w-4' onClick={() => handleRemoveOption3(option)} />
                    </div>
                  ))}
                  {options3.length > 0 ? (
                    <select onChange={handleIntensityChange23} value={selectedOption3} className='select-style'>
                      <option value='' className='bg-gray-200' disabled >Select</option>
                      {options3
                        .filter(option => !selectedOptions3.some(selectedOption => selectedOption.value === option.value)) // Filter out selected options
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='maindiv bg-gray-200 rounded-lg flex flex-col md:flex-row border' >
        <div className='heading text-lg font-medium w-full md:w-[85px] flex '>
          Fluore
          {FluoreCode.length > 0 && (
            <span className='pl-1' onClick={handleClearSelection6}>
              <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
            </span>
          )}
        </div>
        <div className='downPart flex flex-wrap gap-4 items-center'>
          {Floro.map((Floro, index) => (
            <div className='FromTo' key={index}>
              <div className={`FluorescenceOption ${FluoreCode.includes(Floro.code) ? 'selected' : ''}`} onClick={() => handleBoxClick6(Floro.code)}>
                {Floro.name}
              </div>
            </div>
          ))}

          {
            isVendor == 'TRUE' ?
              <div style={{ marginLeft: window.innerWidth <= 450 && '-19px' }} className='md:ml-10 flex !items-center mt-5 md:mt-0 !justify-center '  >
                {/* <label class="labelname pt-1 pr-1">ReportNo:</label> */}
                {/* <div className='colorname w-20 md:w-auto'>Color &nbsp;</div> */}
                <select onChange={(e) => setReport(e.target.value)} value={reportNo} className={`w-40 mr-3`}>
                  <option value='CERT' className='bg-gray-200 labelname pt-1 pr-1' selected >ReportNo</option>
                  <option value='STOCK'>StockID </option>
                  <option value='VENDOR'>VendorID </option>
                  <option value='CLIENT'>ClientID</option>
                </select>
                <div class="quill-container">
                  <input
                    id='yourInputId'
                    value={stoneIdList}
                    className='input1'
                    onChange={(e) => handleQuillChange(e.target.value)}
                    style={{ width: "100%", border: "1px solid grey" }}
                    required
                  />
                </div>
              </div>
              :
              <div style={{ marginLeft: window.innerWidth <= 450 && '-19px' }} className='md:ml-10 flex !items-center mt-5 md:mt-0 !justify-center '  >
                <label class="labelname pt-1 pr-1">ReportNo:</label>
                <div class="quill-container">
                  <input
                    id='yourInputId'
                    value={stoneIdList}
                    className='input1'
                    onChange={(e) => handleQuillChange(e.target.value)}
                    style={{ width: "100%", border: "1px solid grey" }}
                    required
                  />
                </div>
              </div>
          }
        </div>
      </div>


      <div className='maindiv bg-gray-200 rounded-lg m-5 p-4 flex flex-col md:flex-row !mb-4'>
        <div className='flex md:flex md:flex-row w-full md:w-max items-center h-10 '>
          <div className='heading text-lg font-medium self-start flex items-center h-full pr-0 md:pr-5 w-[65px] md:w-[80px] '>
            Lab
            {labCode.length > 0 && (
              <span className='pl-1' onClick={handleClearSelection7}>
                <img src={CloseImg} className='cursor-pointer h-4 w-4 rounded-full' alt='Close' />
              </span>
            )}
          </div>

          <div className='flex flex-col md:flex-row gap-4 px-4'>
            <div className='downPart w-full md:w-1/3'>
              {Labdata.map((Labdata, index) => (
                <div className='FromTo' key={index}>
                  <div className={`colortext ${labCode.includes(Labdata.code) ? 'selected' : ''}`} onClick={() => handleBoxClick7(Labdata.code)} >
                    {Labdata.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='flex-row mt-2 md:mt-0 md:w-max w-full flex items-center'>
          <div className='font-poppins text-lg font-medium w-20'>Location &nbsp;</div>
          <select onChange={(e) => setLocation(e.target.value)} value={location} className={`w-32 border border-black bg-white`}>
            <option value='' className='bg-white' selected >Select</option>
            <option value='INDIA'>INDIA</option>
            <option value='USA'>USA</option>
            <option value='UAE'>UAE</option>
            <option value='HONGKONG'>HONGKONG</option>
            <option value='BANGKOK'>BANGKOK </option>
          </select>
        </div>
        <div className='flex mt-2 flex-row md:ml-4 mr-5 md:mr-0 gap-3 md:mt-[8px] items-center'>
          <label className='flex gap-2 w-full md:w-max flex-row items-center'>
            <input
              type="radio"
              value="LabGrown"
              checked={isLabGrown}
              onChange={handleRadioButtonChange}
            />
            Lab Grown
          </label>
          <label className='flex gap-2 md:ml-0 ml-5 flex-row items-center'>
            <input
              type="radio"
              value="Natural"
              checked={!isLabGrown}
              onChange={handleRadioButtonChange}
            />
            Natural
          </label>
        </div>

        <div className='w-full flex flex-row justify-center md:justify-start md:pl-16 gap-10'>
          <button class="buttonSearch" onClick={handleSearch}>
            Search
          </button>
          <button class="buttonSearch" onClick={handleReset}>
            Reset
          </button>
        </div>
      </div>

      <Modal open={modalIsOpen} footer={null} width={305} closable={false} onCancel={closeModal} centered>
        <label style={{ width: "100%", textAlign: "center", marginLeft: "44px" }}>Are you sure want to Logout?</label>
        <div style={{ display: "flex", justifyContent: "center", gap: "15px", marginTop: "10px" }}>
          <button className='hold-hover' onClick={handleLogOut}>
            Yes
          </button>
          <button className='confirm-hover' onClick={closeModal}>
            No
          </button>
        </div>
      </Modal>
    </div >
  );
}



export default Search;
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom";

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showLoader, setShowLoader] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        const email = localStorage.getItem('USERNAME')
        const password = localStorage.getItem('PASSWORD')

        if (email && password) {
            navigate('/search')
        }
    }, [])
    // Login function called.
    const handleSubmit = async () => {
        setShowLoader(true)
        localStorage.clear()
        if (email === '' || password === '') {
            toast.error('Please enter valid Username or Password!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setShowLoader(false)
            return;
        }
        await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/login`, {
            method: 'POST',
            body: JSON.stringify({
                USERNAME: email,
                PASSWORD: password
            }),
            headers: {
                'Content-type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setShowLoader(false)
                if(data.success === 'TRUE'){
                    navigate('/search')
                    localStorage.setItem("USERNAME", email)
                    localStorage.setItem("PASSWORD", password)
                    localStorage.setItem("IS_CONFIRM", data.message.IS_CONFIRM)
                    localStorage.setItem("IS_VENDOR", data.message.IS_VENDOR)
                    localStorage.setItem("IS_HOLD", data.message.IS_HOLD)
                    localStorage.setItem("IS_CLIENT_PRICE", data.message.IS_CLIENT_PRICE)
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    toast.error(data.message.MSG, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
            .catch((err) => {
                setShowLoader(false)
            });
       
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Trigger the login function when Enter key is pressed
            handleSubmit();
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="login-container">
                <div className="login-form">
                    <h1 style={{ textAlign: "center" }}>Login</h1>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="input"
                        onKeyDown={handleKeyPress}
                    />
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="input"
                        onKeyDown={handleKeyPress}
                    />

                    {
                        !showLoader && <button className="button" onClick={handleSubmit}>Login</button>
                    }
                    {
                        showLoader && <button className="button flex justify-center" disabled>
                            <RotatingLines
                                strokeColor="yellow"
                                strokeWidth="10"
                                animationDuration="0.75"
                                width="17"
                                visible={true}
                            />
                        </button>
                    }

                </div>
            </div>
        </>
    );
}

export default Login;

const Cut = [
    {
        id: 0,
        name: 'EX',
        code: '1',
        value:'EX',
        label:'EX'
    },
    {
        id: 1,
        name: 'VG',
        code: '2',
        value:'VG',
        label:'VG'
    },
    {
        id: 2,
        name: 'GD',
        code: '3',
        value:'GD',
        label:'GD'
    },
    {
        id: 3,
        name: 'FR',
        code: '4',
        value:'FR',
        label:'FR'
    },
    {
        id: 4,
        name: 'P',
        code: '5',
        value:'P',
        label:'P'
    },
    {
        id: 5,
        name: 'ID',
        code: '6',
        value:'ID',
        label:'ID'
    }
]

export default Cut;